import HTTP from "./http";
import {addMessage} from "../store/message";
import {$app, initConfig, setTermID, setToken} from "../store/app";
import URL from "./url";

export default class BACKEND_API {

	// static URL: string = window.location.origin.includes("localhost") ? "https://lspit.x1b.info" : window.location.origin;
	static URL: string = "https://lspit.x1b.info";

	static API: HTTP = new HTTP(BACKEND_API.URL, {});
	static INITED: boolean = false;

	public static async getConfig(): Promise<boolean> {
		if (BACKEND_API.INITED) return false;
		if (!$app.getState().token || !$app.getState().term) {
			addMessage({
				title: "Error",
				description: `Init data - Term: ${$app.getState().term || "NO"}, Token: ${$app.getState().token || "NO"}`
			});
			return false;
		}

		BACKEND_API.INITED = true;

		return await BACKEND_API.API.send(`lobby/slotmachine/init?term_id=${$app.getState().term}&videoslot_token=${$app.getState().token}`, {})
			.then((res: any): boolean => {
				const data = res?.data;
				if (data?.result) {
					initConfig(data.data);
					return true;
				} else {
					addMessage({
						title: "Error",
						description: data?.error_message || "HTTP:init Unhandled error",
						buttons: [{title: "Retry", cb: BACKEND_API.getConfig}]
					});
					BACKEND_API.INITED = false;
					return false;
				}
			})
			.catch((e): boolean => {
				addMessage({
					title: "Error",
					description: `HTTP:init ${e.message}`
				});
				console.error("HTTP ERROR", e);
				BACKEND_API.INITED = false;
				return false;
			});
	}
}

const token = (URL.UrlParams() as { token: string })?.token || localStorage.getItem("token") || "";
setToken(token);
const term = (URL.UrlParams() as { term: string })?.term || localStorage.getItem("term") || "";
setTermID(term);