import style from "./style.module.scss";
import parse from 'html-react-parser';
import {useUnit} from "effector-react";
import {useLayoutEffect} from "react";
import {$remote, clearLamps, flashLamps, RemoteBtn} from "../../store/remote";

import {$app} from "../../store/app";
import {$display} from "../../store/display";

import Header from "../header/Header";

import {ReactComponent as Line1} from "./../../img/icons/line/1.svg";
import {ReactComponent as Line2} from "./../../img/icons/line/2.svg";
import {ReactComponent as Line3} from "./../../img/icons/line/3.svg";
import {ReactComponent as Line4} from "./../../img/icons/line/4.svg";
import {ReactComponent as Line5} from "./../../img/icons/line/5.svg";
import Device from "../../core/device";

const lineImages = [Line1, Line2, Line3, Line4, Line5];

export default function Remote() {
	const app = useUnit($app);
	const display = useUnit($display);
	const remote = useUnit($remote);

	useLayoutEffect(() => {
		btnFlash(remote.lamps);
	}, [remote.lamps]);

	const btnFlash = (data: number) => {
		clearLamps();
		flashLamps(data);
	}

	const onClick = (btn: RemoteBtn) => {
		const readyState = app.controlWS?.WS.readyState() || 0;
		if (app.controlWS && readyState && readyState == 1) {
			app.controlWS.WS.call('pressButton', {'num': btn.id});
		} else {
			console.error("WS ERROR : BTN PRESS", btn.id)
		}
	}

	let styles = "";
	if("small".includes(display.size)) styles = style.small;
	if(Device.isMobile() && display.isLandscape) styles = style.mobileLandscape;

	return (
		<div className={`${style.remote} ${styles}`}>
			{Object.values(remote.buttons).map((group, i) => {
				return <div className={style.group} key={i}>
					{group.map((btn, i) => {
						return <div className={`${style.button} ${btn.light && style.light} ${btn.style}`} key={i}
							onClick={() => onClick(btn)}>
							{btn.img ? (() => {
								const Component = lineImages[btn.img - 1];
								return <Component className={style.light}/>
							})() : null}
							{btn.label && <p className={style.label}>{parse(btn.label)}</p>}
							{btn.title && <p className={style.title}>{btn.title}</p>}
						</div>
					})}
				</div>
			})}
		</div>
	)
}