import {createStore, createEvent} from "effector";
import style from "../components/remote/style.module.scss";
import {FunctionComponent} from "react";

export type RemoteBtn = {
	id: number,
	light: boolean,
	img: number|null,
	label: string,
	title: string,
	style: unknown
}
type RemoteButtons = {
	[name: string]: RemoteBtn[]
}

export type Remote = {
	lamps: number,
	buttons: RemoteButtons
}

const mock = {
	lamps: NaN,
	buttons: {
	a: [
		{id: 4, light: false, img: null, label: "Auto", title: "", style: style.auto},
		{id: 3, light: false, img: null, label: "SELECT", title: "", style: style.select},
		{id: 2, light: false, img: null, label: "Info", title: "", style: style.info}
	],
	b: [
		{id: 5, light: false, img: 1, label: "Line", title: "", style: style.line},
		{id: 6, light: false, img: 2, label: "Lines", title: "", style: style.line},
		{id: 7, light: false, img: 3, label: "Lines", title: "", style: style.line},
		{id: 8, light: false, img: 4, label: "Lines", title: "", style: style.line},
		{id: 9, light: false, img: 5, label: "Lines", title: "", style: style.line}
	],
	c: [
		{id: 10, light: false, img: null, label: "Min", title: "Gamble", style: style.min},
		{id: 12, light: false, img: null, label: "Start", title: "Take win", style: style.start},
		{id: 11, light: false, img: null, label: "Max", title: "Gamble", style: style.max}
	],
}
};

export const setRemoteLamps = createEvent<number>();
export const clearLamps = createEvent();
export const flashLamps = createEvent<number>();
export const clear = createEvent();

export const $remote = createStore<Remote>(mock)
	.on(setRemoteLamps, (state, lamps: number) => {
		return {...state, lamps};
	})
	.on(clearLamps, (state) => {
		Object.values(state.buttons).map((group)=>{
			group.map((btn) => btn.light = false);
		})
		return {...state};
	})
	.on(flashLamps, (state, lamps:number) => {
		for (let i = 0; i < 13; i++) {
			if ((lamps & (1 << i)) != 0) {
				Object.values(state.buttons).map((group)=>{
					group.map((btn) => {
						if (btn.id === (i+1)) {
							btn.light = true;
						}
					});
				})
			}
		}
		return {...state};
	})
	.reset(clear);
