import {createStore, createEvent} from "effector";

export type BTN = {
	title: string,
	cb?: Function,
	color?: string
}
export type MSG = {
	title: "Error"|"Success",
	description: string,
	buttons?: BTN[]
}

export type Message = {
	messages: MSG[],
}

const mock = {
	messages: [],
};

export const addMessage = createEvent<MSG>();
export const remMessage = createEvent<number>();
export const clear = createEvent();

export const $message = createStore<Message>(mock)
	.on(addMessage, (state, msg: MSG) => {
		state.messages.push(msg); return {...state};
	})
	.on(remMessage, (state, index: number) => {
		state.messages.splice(index,1); return {...state};
	})
	.reset(clear);
