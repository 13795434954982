import {createStore, createEvent} from "effector";

export type DisplaySize = "small" | "medium" | "large";

export type Header = {
	size: DisplaySize,
	isLandscape: boolean,
}

const mock = {
	size: "" as DisplaySize,
	isLandscape: true,
}

export const setSize = createEvent<DisplaySize>();
export const setIsLandscape = createEvent<boolean>();
export const clear = createEvent();

export const $display = createStore<Header>(mock)
	.on(setSize, (state, size) => {
		return {...state, size};
	})
	.on(setIsLandscape, (state, isLandscape: boolean) => {
		return {...state, isLandscape};
	})
	.reset(clear);
