import WSRPC from "./wsrpc";
import {$app, setControlWS} from "../store/app";

export default class controlWS {
	WS: WSRPC;

	constructor(options: any, onopen: Function, onmessage: Function) {
		this.WS = new WSRPC(options.url, options,
			(msg: any) => {
				onmessage(msg);
			},
			() => {
				if (this.WS) this.WS.call('login', {
					token: $app.getState().token,
					route: options.route,
					term_sess: $app.getState().config!.term_sess
				}, (err: any, data: any) => {
					if (err) {
						this.WS.closeWS();
						setControlWS(null);
						console.error(err)
						return false;
					}
					// if (onopen) onopen(data);
				});
				if (onopen) onopen(this);
			}
		);

		this.WS.on('open', () => {
		});
		this.WS.addRoute('gotoUrl', this.gotoUrl);
		this.WS.addRoute('connectionClose', this.connectionClose);
		this.WS.addRoute('ping', this.ping);
		this.WS.addRoute('buttonEvent', () => {
			console.log("12345")
		});
		this.WS.connect(true);
	}

	connectionClose() {
		this.WS.close();
		console.error('Error', 'disconnect_other_tab');
	};

	gotoUrl(data: any) {
		// ...
	};

	ping() {
		this.WS.clearPingCount();
		// this.WS.callback(this.serial);
	};
}