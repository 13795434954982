import {createStore, createEvent} from "effector";
import {useNavigate} from "react-router-dom";

type Sound = {
	enabled: boolean,
}

const mock = {
	enabled: false,
};

export const setIsSound = createEvent<boolean>();
export const clear = createEvent();

export const $sound = createStore<Sound>(mock)

	.on(setIsSound, (state, enabled: boolean) => {
		return {...state, enabled};
	})
	.reset(clear);
