import style from "./style.module.scss";
import Message from "./components/message/Message";
import Device from "./core/device";
import Display from "./core/display";
import {useLayoutEffect} from "react";
import BACKEND_API from "./core/api";
import SlotMachine from "./pages/SlotMachine";
import {useUnit} from "effector-react";
import controlWS from "./libs/controlWS"
import {$app, setControlWS} from "./store/app";
import {$display} from "./store/display";
import {setRemoteLamps} from "./store/remote";

export const App = () => {
    const app = useUnit($app);
    const display = useUnit($display);
    let ws: controlWS

    useLayoutEffect(() => {
        BACKEND_API.getConfig().then((success) => {
        });
    }, []);

    useLayoutEffect(() => {
        console.log("CREATE SOCKET", !!app.config, !!app.controlWS);
        if (app.config && !app.controlWS) {
            ws = new controlWS(
                app.config.controlWS,
                (ws: controlWS) => {
                    setControlWS(ws);
                },
                (data: any) => {
                    switch (data.action) {
                        case "buttonEvent": {
                            setRemoteLamps(data.data.L);
                        }
                    }
                }
            )
        }
    }, [app.config]);

    let styles = "";
    if ("small".includes(display.size)) styles = `${styles} ${style.small}`
    if (Device.isMobile() && Device.isIOS()) styles = `${styles} ${style.isIOS}`
    if (Device.isMobile() && display.isLandscape) styles = `${styles} ${style.mobileLandscape}`

    return (
        <>
            <div className={`${style.app} ${styles} roboto-mono-500`}
                 onClick={() => (Device.isMobile() && !Device.isIOS()) && Display.isFullScreen(true)}
            >
                <div className={`${style.content}`}>
                    {app.controlWS && app.appIsActive
                        ? <SlotMachine/>
                        : <p style={{width: "100%", textAlign: "center"}}>DISCONNECTED</p>}
                </div>
            </div>
            <Message/>
            {Device.isMobile() && Device.isIOS() && <div className={style.ios_scroll_crunch}/>}
        </>
    );
}