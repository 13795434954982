import style from "./style.module.scss";
import preloader from "./../../img/icons/preloader.svg"
// @ts-ignore
import * as ReactJanus from "react-janus";
import {useEffect, useRef, useState} from "react";
import {useUnit} from "effector-react";
import {$app} from "../../store/app";
import Device from "../../core/device";
import {setIsSound} from "../../store/sound";

export enum STREAM_TYPE {WebRTC, MPEG};
export type VECTOR2D = { x: number, y: number }

export type STREAM = {
	id: string,
	janus: ReactJanus.Janus,
	type: STREAM_TYPE,
	url: { webrtc: string, mpeg: string },
	size: VECTOR2D,
	isTouch: boolean,
	place: number,
	isSound?: boolean,
	style?: object
}

export default function VideoStream(props: STREAM) {
	const app = useUnit($app);
	const video = useRef<HTMLVideoElement>(null);
	const place = (props.id.includes('camera') ? 0 : 1000) + props.place;

	const [isAttached, setIsAttached] = useState(false);

	useEffect(() => {
		console.log("MUTED", video.current!.muted, video.current)
	}, [props.isSound]);

	useEffect(() => {
		let janus: ReactJanus.Janus;
		let streaming:any = null;

		console.log("START STREAM", props, !!ReactJanus.Janus)

		const attach = props.janus.attach(
		{
			plugin: "janus.plugin.streaming",
			opaqueId: "streamingtest"+ReactJanus.Janus.randomString(12),
			success: (pluginHandle: any)=>{
				streaming = pluginHandle;
				startStream(place, pluginHandle);
				setIsAttached(true);
			},
			onmessage: function (msg:any, jsep:any) {
				let result = msg["result"];
				if (jsep !== undefined && jsep !== null) {
					streaming.createAnswer(
						{
							jsep: jsep,
							media: {
								audioSend: false,
								videoSend: false,
								data: true
							},
							success: (jsep:any)=>{
								let body = {"request": "start"};
								streaming.send({"message": body, "jsep": jsep});
							}
						});
				}
			},
			onremotestream: (stream:any)=>{
				ReactJanus.Janus.attachMediaStream(video.current, stream);
				console.log("VIDEO TAG", video.current, ReactJanus.Janus.sessions)
			},

			ondataopen: function (data:any) {
				console.warn("ondataopen", data)
			},
			ondata: function (data:any) {
				console.warn("ondata", data)
			},
			oncleanup: function (data:any) {
				console.warn("oncleanup", data)
			},
			onconnectionstatechange: function (data:any) {
				console.warn("CON CHANGE",data)
			},
			ondetached: function (data:any) {
				console.warn("DETACHED",data)
			}
		});

		return ()=>{
			streaming?.detach();
		}

	}, []);

	function startStream(id:number, streaming:any) {
		ReactJanus.Janus.log("Starting display translation ID:" + id);
		let body = { "request": "watch", id:id };
		streaming.send({"message": body});
	}

	function touch(e:any) {
		// console.log("TOUCH A", app.config && e.nativeEvent.which === 1, e)
		if (app.config && e.nativeEvent.which === 1) {
			let koeffX = 0, koeffY = 0;
			if (app.config.term_type === 7) {
				koeffX = Math.round(5000 / e.target.offsetWidth);
				koeffY = Math.round(2812 / e.target.offsetHeight);
			} else if (app.config.term_type === 5) {
				koeffX = Math.round(32000 / e.target.offsetWidth);
				koeffY = Math.round(32000 / e.target.offsetHeight);
			} else {
				koeffX = Math.round(65535 / e.target.offsetWidth);
				koeffY = Math.round(65535 / e.target.offsetHeight);
			}
			let currentX = 0, currentY = 0;
			if (app.config.term_type === 5) {
				currentX = ceil256(koeffX * e.nativeEvent.offsetX);
				currentY = ceil256(koeffY * (e.target.offsetHeight - e.nativeEvent.offsetY));
			} else {
				currentX = koeffX * e.nativeEvent.offsetX;
				currentY = koeffY * e.nativeEvent.offsetY;
			}
			if (app.config.term_type === 1) {
				if (currentX >= 847 && currentX <= 8547 && currentY >= 57933 && currentY <= 65190) return;
			}
			let readyState = app.controlWS?.WS.readyState() || false;
			if (readyState && readyState === 1) {
				// console.log("TOUCH B", {'x':currentX,'y':currentY}, )
				app.controlWS?.WS.call('touch',{'x':currentX,'y':currentY});
			} else {
				console.error("WS ERROR : SENSOR", {'x':currentX,'y':currentY})
			}
		}
	}

	function ceil256(x:number) {
		return Math.ceil(x / 256) * 256;
	}

	function onStreamClick() {
		if(Device.isMobile()) setIsSound(true);
	}

	return (
		<div className={style.stream} style={props.style} onClick={onStreamClick}>
			{/*<div className={style.debug}>*/}
			{/*	<p>{`Place ${(props.id.includes('camera') ? 0 : 1000) + props.place}`}</p>*/}
			{/*	<p>{`Attached ${isAttached}`}</p>*/}
			{/*	<p>{`Sound ${props.isSound?"ON":"OFF"}`}</p>*/}
			{/*	<p>{`Touch ${props.isTouch?"ON":"OFF"}`}</p>*/}
			{/*</div>*/}
			<div className={style.preloader}>
				<img src={preloader} alt=""/>
			</div>
			<video id={"tr"} ref={video} autoPlay muted={!props.isSound} playsInline/>
			{ props.isTouch && <div className={style.sensor} onClick={touch}></div> }
		</div>
	)
};