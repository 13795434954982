import {createStore, createEvent} from "effector";
import controlWS from "../libs/controlWS";

type Currency = {
	iso_name: string,
	int_name: string,
}
type Account = {
	currency: Currency,
	login: string
}
type Config = {
	account: Account,

	server_webrtc: string,
	server_cam_webrtc: string,
	server_mobile_streaming: string,
	term_touchscreen: boolean,

	term_id: number,
	term_type: number,
	term_place: number,
	term_sess: number,

	controlWS: {
		route: string,
		url: string
	}
}

export type App = {
	appIsActive: boolean,
	config: Config | null,
	isCamera: boolean,
	token: string,
	term: string
	controlWS: controlWS | null
}

const mock = {
	appIsActive: true,
	config: null,
	isCamera: true,
	token: "",
	term: "",
	controlWS: null
};

export const initApp = createEvent<App>();
export const initConfig = createEvent<Config>();
export const setAppIsActive = createEvent<boolean>();
export const setToken = createEvent<string>();
export const setTermID = createEvent<string>();
export const setIsCamera = createEvent<boolean>();
export const setIsSound = createEvent<boolean>();
export const setControlWS = createEvent<controlWS|null>();
export const clear = createEvent();

export const $app = createStore<App>(mock)
	.on(initApp, (state, newState: App) => {
		return {...state, ...newState};
	})
	.on(initConfig, (state, config: Config) => {
		console.log("Config OK:", config);
		return {...state, config};
	})
	.on(setAppIsActive, (state, appIsActive: boolean) => {
		console.log("App is active:", appIsActive);
		return {...state, appIsActive};
	})
	.on(setToken, (state, token: string) => {
		localStorage.setItem("token", token);
		return {...state, token};
	})
	.on(setTermID, (state, term: string) => {
		localStorage.setItem("term", term);
		return {...state, term};
	})
	.on(setIsCamera, (state, isCamera: boolean) => {
		return {...state, isCamera};
	})
	.on(setControlWS, (state, controlWS: controlWS|null) => {
		console.log("Socket set to:", controlWS);
		return {...state, controlWS};
	})
	.reset(clear);
