import style from "./style.module.scss";
import {useUnit} from "effector-react";
import {$message, remMessage} from "../../store/message";
import close from "../../img/icons/close.svg"

export default function Message() {
	const message = useUnit($message);

	return (
		message.messages.length ? <div className={style.messages}>
			<div className={style.roll}>
				{ message.messages.map((msg, i)=>{
					return <div className={style.message} key={i}>
						<img src={close} onClick={()=>remMessage(i)} alt=""/>
						<p>{msg.title}</p>
						<p>{msg.description}</p>
						{msg.buttons?.length &&
							<div className={style.buttons}>
								{ msg.buttons.map((btn, i)=>{
									return <p key={i}
										style={{background:btn.color}}
										onClick={()=>{
											if(btn?.cb) { btn.cb(); remMessage(i); }
											else remMessage(i)
										}}
									>
										{btn.title}
									</p>
								})}
							</div>
						}

					</div>
				})}
			</div>
		</div> : null
	)
}