import style from "./style.module.scss";
import {useUnit} from "effector-react";
import {$app, setIsCamera} from "../store/app";

import controlWS from "../libs/controlWS"

// @ts-ignore
import * as ReactJanus from "react-janus";

import VideoStream, {STREAM_TYPE} from "../components/video/VideoStream";
import Header from "../components/header/Header";
import Remote from "../components/remote/Remote";
import {$display} from "../store/display";
import {useEffect, useState} from "react";
import Display from "../core/display";
import {$sound, setIsSound} from "../store/sound";
import Device from "../core/device";

import camera_on from "../img/icons/camera.svg";
import camera_off from "../img/icons/camera-off.svg";
import sound_on from "../img/icons/sound.svg";
import sound_off from "../img/icons/sound-off.svg";

let janus: ReactJanus.Janus = null;

export default function SlotMachine() {
	const app = useUnit($app);
	const display = useUnit($display);
	const sound = useUnit($sound);
	const server = "wss://japit.x1b.info:7090";

	const [isAlter, setIsAlter] = useState<boolean>(false);
	const [numberDisplay, setNumberDisplay] = useState<number>(-1);
	const [janusInited, setJanusInited] = useState<boolean>(false);

	useEffect(() => {
		if (!janusInited) {
			ReactJanus.Janus.init();
			janus = new ReactJanus.Janus({
				server: server,
				onconnectionstatechange: (data: any) => {
					console.warn("Change", data)
				},
				connectionstatechange: (data: any) => {
					console.warn("Change", data)
				},
				success: () => {
					setJanusInited(true);
				},
				error: (msg: string) => {
					console.error(msg);
					setJanusInited(false);
				}
			});
		}

		return () => {
			// janus?.destroy();
			// setJanusInited(false);
		}

	}, [app.config]);

	function switchDisplay() {
		if (app.config) {
			if (numberDisplay === 0) {
				pressButton(2);
			} else {
				if (app.controlWS) {
					app.controlWS.WS.call('switchDisplay',
						{
							'state_switch_display': 0,
							'term_place': app.config.term_place,
							'term_id': app.config.term_id
						}, (err: any, data: any) => {
							if (err) {
								console.log(`switchDisplay(${0}):`, err);
								return;
							}
							setNumberDisplay(data.numberDisplay);
							console.log(`switchDisplay(${data.numberDisplay}):`)
						}
					);
				} else {
					console.error("CONTROL_WS NOT WORK")
				}
			}
		}
	}

	function pressButton(id: number) {
		if (app.controlWS) {
			const readyState = app.controlWS.WS.readyState();
			if (readyState && readyState == 1) app.controlWS.WS.call('pressButton', {'num': id});
		}
	}

	let styles = "";
	if("small".includes(display.size)) styles = `${styles} ${style.small}`
	if(Device.isMobile() && display.isLandscape) styles = `${styles} ${style.mobileLandscape}`

	// const videoStyle = `width: ${"small".includes(display.size) ? 100 : 100}%`

	return (
		<div className={`${style.slotMachine} ${styles}`}>
			{!"small".includes(display.size) && !(Device.isMobile() && display.isLandscape) && <Header/> }
			<div className={`${style.streams} ${!app.config && style.center}`}>
				{/*{janusInited && app.config && app.isCamera && !"small".includes(display.size) &&*/}
				{janusInited && app.config && app.isCamera &&
					<>
						{isAlter ? <iframe scrolling="no" src={`https://ks50.ru/rasp/jacamera.php?term=${app.config.term_place}`}/> :
							<VideoStream
								id={"camera"}
								janus={janus}
								isTouch={false}
								place={app.config.term_place || 105}
								size={{x: 140, y: 220}}
								type={STREAM_TYPE.WebRTC}
								url={{
									mpeg: app.config.server_mobile_streaming || "wss://wss.liveslotsmachine.com:9995",
									webrtc: app.config.server_cam_webrtc || "wss://japit.x1b.info:7090"
								}}
								style={{width: "40%"}}
							/>
						}
					</>
				}
				{janusInited && app.config &&
					<>
						{isAlter ? <iframe scrolling="no" src={`https://ks50.ru/rasp/jadisplay.php?term=${app.config.term_place}`}/> :
							<VideoStream
								id={"display"}
								janus={janus}
								isTouch={app.config.term_touchscreen || false}
								place={app.config.term_place || 105}
								size={{x: 530, y: 270}}
								type={STREAM_TYPE.WebRTC}
								url={{
									mpeg: app.config.server_mobile_streaming || "wss://wss.liveslotsmachine.com:9995",
									webrtc: app.config.server_webrtc || "wss:///japit.x1b.info:7090"
								}}
								isSound={sound.enabled}
								style={{width: "100%"}}
								// style={{videoStyle}}
							/>
						}
					</>
				}
				{app.config &&
                    <div className={style.camera}>
                        <img src={app.isCamera ? camera_on : camera_off} onClick={() => {
							setIsCamera(!app.isCamera)
						}}></img>
						{ Device.isMobile() && <img src={sound.enabled ? sound_on : sound_off} onClick={() => {
							setIsSound(!sound.enabled)
						}} alt=""/> }
                    </div>


				}
				{/*{app.config &&*/}
				{/*	<div className={style.switch} onClick={switchDisplay}></div>*/}
				{/*}*/}
				{!app.config && <p>NO DATA</p>}
			</div>
			<Remote/>
		</div>
	)
}