import axios from "axios";
import {AxiosRequestConfig} from "axios";

export type Response = {
	message: string,
	result: boolean,
}

export default class HTTP {
	url: string = "";
	config: AxiosRequestConfig = {
		headers: {

		}
	};

	constructor(url: string, config: AxiosRequestConfig) {
		this.url = url;
		this.config = {...this.config, ...config};
	}

	public async send(action: string, payload: any = null) {
		return new Promise((resolve, reject) => {
			const send = (payload && Object.keys(payload).length ? axios.post : axios.get);
			send(`${this.url}/${action}`, payload, this.config)
				.then((response: unknown) => {
					resolve(response);
				}).catch((e) => {
				reject(e);
			})
		})
	}
}
