import React from "react";

import {App} from "./App";
import {createRoot} from "react-dom/client";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Display from "./core/display";
import {setAppIsActive} from "./store/app";

Display.startService();

const router = createBrowserRouter([
	{
		path: "/",
		element: <App/>,
	}
]);

let title = document.title;
document.addEventListener("visibilitychange", function(){
	setAppIsActive(!document.hidden);
	document.title = document.hidden ? `${title} (Sleep)` : title;
});

// Time.startService();
// Socket.StartService();

createRoot(document.getElementById('root')!).render(
	<RouterProvider router={router} />
);