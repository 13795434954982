import style from "./style.module.scss";
import sound_on from "../../img/icons/sound.svg";
import sound_off from "../../img/icons/sound-off.svg";
import {$sound, setIsSound} from "../../store/sound";
import {useUnit} from "effector-react";
import {$display} from "../../store/display";

export default function Header() {
	const display = useUnit($display);
	const sound = useUnit($sound);

	return (
		<div className={`${style.header} ${"small".includes(display.size) && style.small}`}>
			<img src={sound.enabled ? sound_on : sound_off} onClick={()=>{ setIsSound(!sound.enabled) }} alt=""/>
		</div>
	)
}