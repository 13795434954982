import {setIsLandscape} from "../store/display";
import {DisplaySize, setSize} from "../store/display";

export default class Display {

	public static Size = {
		small: 0,
		medium: 840,
		large: 1300,
		huge: 1800
	};

	static startService() {
		window.addEventListener('resize', Display.windowOnResize);
		Display.windowOnResize();
	}

	static windowOnResize() {
		setIsLandscape(window.innerWidth > window.innerHeight);

		Object.keys(Display.Size).map((key) => {
			// @ts-ignore
			if (Display.Size[key] <= window.innerWidth) {
				setSize(key as DisplaySize);
			}
		});
	}

	static isFullScreen(state: boolean, el?: HTMLElement) {
		const doc = window.document;
		const docEl = doc.documentElement;

		const requestFullScreen = el
			? el.requestFullscreen // @ts-ignore
			: docEl.requestFullscreen || docEl?.mozRequestFullScreen || docEl?.webkitRequestFullScreen;
		// @ts-ignore
		const cancelFullScreen = doc.exitFullscreen || doc?.mozCancelFullScreen || doc?.webkitExitFullscreen;

		// @ts-ignore
		if (!doc.fullscreenElement && !doc?.mozFullScreenElement && !doc?.webkitFullscreenElement) {
			if (state) requestFullScreen.call(docEl);
		} else {
			if (!state) cancelFullScreen.call(doc);
		}
	}

	static getIsFullscreen() {
		const doc = window.document;
		// @ts-ignore
		return (doc?.fullscreenElement || doc?.mozFullScreenElement || doc?.webkitFullscreenElement);
	}

	static getWindowSize = function (withScrollBar: boolean = true) {
		let wid = 0;
		let hei = 0;
		if (typeof window.innerWidth != "undefined") {
			wid = window.innerWidth;
			hei = window.innerHeight;
		} else {
			if (document.documentElement.clientWidth == 0) {
				wid = document.body.clientWidth;
				hei = document.body.clientHeight;
			} else {
				wid = document.documentElement.clientWidth;
				hei = document.documentElement.clientHeight;
			}
		}
		return {width: wid - (withScrollBar ? (wid - document.body.offsetWidth + 1) : 0), height: hei};
	};
}
