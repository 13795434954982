export default class WSRPC {

	options: any = null;
	url: string = "";

	socket: WebSocket | null = null;
	serial: number = 0;

	pingTimer: any = 0;

	attempt: number = 1;
	events: any = {};
	store: any = {};
	storeCallback: any = {};
	routes: any = {};
	reconnectOnClose: boolean = true;
	pingcount: number = 0;

	onmessage: Function;
	onopen: Function;

	constructor(url: string, options: any, onmessage:Function, onopen:Function) {
		this.options = options;
		this.url = url;
		this.onmessage = onmessage;
		this.onopen = onopen;
	}

	reconnect() {
		setTimeout(() => {
			try {
				this.socket = this.createSocket();
				this.serial = 0;
			} catch (exc) {
				// @ts-ignore
				delete this.socket;
			}
		}, 1000);
	}

	createSocket(usePing?: boolean) {
		if (usePing === undefined) usePing = false;
		this.pingcount = 0;
		let ws = new WebSocket(this.url);
		ws.onopen = () => {
			this.attempt = 1;
			console.log('connected WS');
			this.onopen();
		};
		ws.onclose = () => {
			console.log('disconnect WS');
			if (this.attempt > 3) {
				console.log('error connect');
				//return new modalWindow().content($('<p>', {text: dict('connection_error')})).show('modalError');
			}
			console.log('Reconnect attempt ' + this.attempt);
			this.attempt++;
			if (this.reconnectOnClose) this.reconnect();
		};
		ws.onerror = function () {
		};
		ws.onmessage = (evn) => {
			var response;
			try {
				response = JSON.parse(evn.data);
			} catch (e) {
				// ...
			}
			this.onmessage(response);
			if (response.result) {

				var err = null;
				if (response.result !== 0) {
					err = response.message;
				}
				if (response.type == 'callback' && response.serial) {
					if (this.store[response.serial]) {
						this.store[response.serial](err, response.data, this.storeCallback[response.serial]);
						delete this.storeCallback[response.serial];
						delete this.store[response.serial];
					}
				} else if (response.type == 'call' && response.serial && response.action) {
					// @ts-ignore
					if (this.routes[response.action]) this.routes[response.action].call(response, response.data)
				}
			}

		};
		// if (usePing) {
		// 	this.pingTimer = setInterval(() => {
		// 		if (this.pingcount > 1) {
		// 			this.reconnectOnClose = false;
		// 			ws.close();
		// 		}
		// 		this.pingcount++;
		// 	}, 15000);
		// }
		return ws;
	}

	clearPingCount() {
		this.pingcount = 0;
	}

	connect(usePing: boolean) {
		this.socket = this.createSocket(usePing);
	}

	close() {
		this.reconnectOnClose = false;
	}

	closeWS() {
		this.reconnectOnClose = false;
		if (this.socket) this.socket.close();
	}

	on(event: any, cb: Function) {
		this.events[event] = cb;
	}

	call(action: string, data: any, callback?: Function) {
		if (typeof data == "function") {
			callback = data;
			data = {};
		}
		this.serial++;
		if (this.socket && this.socket.readyState && this.socket.readyState == 1)
			this.socket.send(JSON.stringify({
				serial: this.serial,
				action: action,
				type: 'call',
				data: data
			}));
		this.store[this.serial] = callback;
		if (callback) this.storeCallback[this.serial] = callback;
	}

	callback(serial: any, data: any) {
		if (this.socket && this.socket.readyState && this.socket.readyState == 1)
			this.socket.send(JSON.stringify({
				result: 0,
				serial: serial,
				type: 'callback',
				data: data
			}));
	}

	addRoute(route: any, cb: Function) {
		this.routes[route] = cb;
	}

	readyState() {
		if (this.socket) return this.socket.readyState;
	}

};